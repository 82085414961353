<template>
  <div class="p-2 information-background-modal">
    <v-carousel
      :continuous="false"
      :cycle="cycle"
      height="100%"
    >
      <v-carousel-item
        v-for="(layer, i) in layersInfo"
        :key="i"
        :style="{
          'background-image':
            'url('
            + require('@/assets/information/' + layer.background)
            + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'border-radius': '5px',
          'background-position': 'center',
        }"
      >
        <div class="flex justify-between ml-3 mt-3">
          <div
            v-for="(locale, index) in locales"
            id="locales"
            :key="locale"
            :value="locale"
          >
            <p
              v-t="index === 0 ? locale.toUpperCase() + ' /' : locale.toUpperCase()"
              class="information-content--language"
              :style="$i18n.locale === locale ? 'font-weight: 500 !important;' : ''"
              @click="setLanguage(locale)"
              @keydown.enter="setLanguage(locale)"
            />
          </div>
          <HeaderModal class="pr-2" :content-sections="['close']" @close="$emit('closeDialog')" />
        </div>
        <div
          :class="(layer.bigFlex && !mobile) ? 'information-content' : 'information-content--no-flex'"
          class="information-content--global"
        >
          <ImageAtom
            :source="require('@/assets/information/' + (mobile ? layer.imageMobile : layer.image))"
            :alt-text="mobile ? layer.imageMobile : layer.image"
            :large="mobile ? false : true"
            class="information-content--image"
            :style="{ '--image-width': layer.widthImage }"
            :class="(layer.bottom && !mobile) ? 'information-content--image-bottom' : ''"
          />
          <div class="information-content--text" :style="{ '--text-width': layer.widthText, '--margin': layer.margin }">
            <MainTitle
              :text="layer.title"
              :left-align="mobile ? false : true"
              :tiny="mobile ? true : false"
              no-margin
              neutral
            />
            <SmallTitle
              class="mt-2"
              :text="layer.subtitle"
              :tiny="mobile ? true : false"
              primary
              center-left
              no-margin
              neutral
            />
          </div>
        </div>
      </v-carousel-item>
      <template #prev="{ on, attrs }">
        <div class="my-custom-prev" v-bind="attrs" v-on="on">
          <v-icon color="#919191">
            mdi-chevron-left
          </v-icon>
        </div>
      </template>
      <template #next="{ on, attrs }">
        <div class="my-custom-next " v-bind="attrs" v-on="on">
          <span v-if="!mobile" v-t="next" />
          <v-icon color="#919191">
            mdi-chevron-right
          </v-icon>
        </div>
      </template>
    </v-carousel>
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import HeaderModal from '@/components/molecules/modals/Header.vue';
import { mapActions } from 'vuex';

export default {
  name: 'InformationPages',
  components: {
    HeaderModal,
    ImageAtom,
    MainTitle,
    SmallTitle,
  },
  data() {
    return {
      cycle: false,
      locales: ['en', 'es'],
    };
  },
  computed: {
    next() {
      return this.$t('information.next');
    },
    layersInfo() {
      const layers = [
        {
          title: this.$t('information.layer-one.title'),
          subtitle: this.$t('information.layer-one.subtitle'),
          image: `welcome-map-nh-${this.$i18n.locale}.png`,
          imageMobile: 'welcome-map-nh-m.png',
          background: 'wallpaper-info-1.svg',
          bigFlex: true,
          textFlex: false,
          widthImage: this.mobile ? '60%' : '100%',
          widthText: this.mobile ? '100%' : '50%',
          margin: 'auto',
          bottom: false,
        },
        {
          title: this.$t('information.layer-two.title'),
          subtitle: this.$t('information.layer-two.subtitle'),
          image: `dashboard-nh-${this.$i18n.locale}.jpg`,
          imageMobile: `dashboard-nh-m-${this.$i18n.locale}.png`,
          background: 'wallpaper-info-2.svg',
          bigFlex: false,
          textFlex: true,
          widthImage: this.mobile ? '60%' : '60%',
          widthText: this.mobile ? '100%' : '60%',
          margin: 'auto',
          bottom: false,
        },
        {
          title: this.$t('information.layer-three.title'),
          subtitle: this.$t('information.layer-three.subtitle'),
          image: `digital-profile-nh-${this.$i18n.locale}.png`,
          imageMobile: `digital-profile-nh-m-${this.$i18n.locale}.png`,
          background: 'wallpaper-info-3.svg',
          bigFlex: true,
          textFlex: false,
          widthImage: this.mobile ? '100%' : '35%',
          widthText: this.mobile ? '100%' : '40%',
          margin: 'auto',
          bottom: true,
        },
        {
          title: this.$t('information.layer-four.title'),
          subtitle: this.$t('information.layer-four.subtitle'),
          image: `favorite-list-nh-${this.$i18n.locale}.jpg`,
          imageMobile: `favorite-list-nh-m-${this.$i18n.locale}.png`,
          background: 'wallpaper-info-white.svg',
          bigFlex: false,
          textFlex: false,
          widthImage: this.mobile ? '60%' : '90%',
          widthText: this.mobile ? '100%' : '40%',
          margin: 'none',
          bottom: false,
        },
        {
          title: this.$t('information.layer-five.title'),
          subtitle: this.$t('information.layer-five.subtitle'),
          image: `simulation-nh-${this.$i18n.locale}.jpg`,
          imageMobile: `simulation-nh-m-${this.$i18n.locale}.png`,
          background: 'wallpaper-info-white.svg',
          bigFlex: true,
          textFlex: false,
          widthImage: this.mobile ? '70%' : '50%',
          widthText: this.mobile ? '100%' : '40%',
          margin: 'auto',
          bottom: true,
        },
        {
          title: this.$t('information.layer-six.title'),
          subtitle: this.$t('information.layer-six.subtitle'),
          image: `apply-nh-${this.$i18n.locale}.jpg`,
          imageMobile: `apply-nh-m-${this.$i18n.locale}.png`,
          background: 'wallpaper-info-4.svg',
          bigFlex: false,
          textFlex: false,
          widthImage: this.mobile ? '60%' : '60%',
          widthText: this.mobile ? '100%' : '60%',
          margin: 'auto',
          bottom: false,
        },
      ];
      return layers;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      retrieveOptions: 'options/retrieveOptions',
      setLocale: 'locale/setLocale',
    }),
    setLanguage(language) {
      this.$i18n.locale = language;
      this.setLocale(language);
      this.retrieveOptions({ reset: true });
    },
  },
};
</script>
