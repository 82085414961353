var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2 information-background-modal"},[_c('v-carousel',{attrs:{"continuous":false,"cycle":_vm.cycle,"height":"100%"},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"my-custom-prev"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"#919191"}},[_vm._v(" mdi-chevron-left ")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"my-custom-next"},'div',attrs,false),on),[(!_vm.mobile)?_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.next),expression:"next"}]}):_vm._e(),_c('v-icon',{attrs:{"color":"#919191"}},[_vm._v(" mdi-chevron-right ")])],1)]}}])},_vm._l((_vm.layersInfo),function(layer,i){return _c('v-carousel-item',{key:i,style:({
        'background-image':
          'url('
          + require('@/assets/information/' + layer.background)
          + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'border-radius': '5px',
        'background-position': 'center',
      })},[_c('div',{staticClass:"flex justify-between ml-3 mt-3"},[_vm._l((_vm.locales),function(locale,index){return _c('div',{key:locale,attrs:{"id":"locales","value":locale}},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(index === 0 ? locale.toUpperCase() + ' /' : locale.toUpperCase()),expression:"index === 0 ? locale.toUpperCase() + ' /' : locale.toUpperCase()"}],staticClass:"information-content--language",style:(_vm.$i18n.locale === locale ? 'font-weight: 500 !important;' : ''),on:{"click":function($event){return _vm.setLanguage(locale)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setLanguage(locale)}}})])}),_c('HeaderModal',{staticClass:"pr-2",attrs:{"content-sections":['close']},on:{"close":function($event){return _vm.$emit('closeDialog')}}})],2),_c('div',{staticClass:"information-content--global",class:(layer.bigFlex && !_vm.mobile) ? 'information-content' : 'information-content--no-flex'},[_c('ImageAtom',{staticClass:"information-content--image",class:(layer.bottom && !_vm.mobile) ? 'information-content--image-bottom' : '',style:({ '--image-width': layer.widthImage }),attrs:{"source":require('@/assets/information/' + (_vm.mobile ? layer.imageMobile : layer.image)),"alt-text":_vm.mobile ? layer.imageMobile : layer.image,"large":_vm.mobile ? false : true}}),_c('div',{staticClass:"information-content--text",style:({ '--text-width': layer.widthText, '--margin': layer.margin })},[_c('MainTitle',{attrs:{"text":layer.title,"left-align":_vm.mobile ? false : true,"tiny":_vm.mobile ? true : false,"no-margin":"","neutral":""}}),_c('SmallTitle',{staticClass:"mt-2",attrs:{"text":layer.subtitle,"tiny":_vm.mobile ? true : false,"primary":"","center-left":"","no-margin":"","neutral":""}})],1)],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }