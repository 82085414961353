<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="campus-search-bar"
    :class="{
      'campus-search-bar--mobile': mobile,
      'campus-search-bar--dashboard': inDashboard,
      'campus-search-bar--dashboard--mobile': inDashboard && mobile,
    }"
  >
    <div
      class="campus-search-bar__input campus-search-bar__input--center"
      :class="{ 'campus-search-bar__input--mobile': mobile }"
    >
      <v-text-field
        v-model="searchTerm"
        v-click-outside="{
          handler: cleanResults,
        }"
        :loading="loading"
        clearable
        hide-details
        item-text="campus_name"
        :label="setTextFieldLabel()"
        :prepend-inner-icon=" mobile ? '' : 'mdi-magnify'"
        solo
        @focus="toggleSearchBar(true)"
      />
      <v-expand-transition>
        <v-list
          v-if="
            searchBarIsFocused && (suggestedCampuses.length > 0 || (searchTerm?.length > 0 && !loading))"
          class="campus-search-bar__results"
          :class="{
            'campus-search-bar__results--mobile': mobile,
            'campus-search-bar__results--dashboard': inDashboard,
            'campus-search-bar__results--dashboard--mobile': inDashboard && mobile,
          }"
        >
          <v-subheader
            v-if="0 < searchTerm?.length && searchTerm?.length < 3"
            class="campus-search-bar__results__header"
          >
            <span v-t="'views.schools.results.min-characters'" />
          </v-subheader>
          <v-subheader
            v-if="suggestedCampuses.length > 0 || (searchTerm?.length > 2)"
            class="campus-search-bar__results__header"
          >
            <span>
              {{ resultHeaderText }}
            </span>
          </v-subheader>
          <v-list-item
            v-for="place in suggestedPlaces"
            :key="place.p_location_id"
            :title="`${place.city} ( ${place.upper_name} )`"
            @click="selectPlace(place)"
          >
            <v-list-item-avatar
              tile
              :color="'#1E0C61'"
              class="campus-search-bar__results__item--avatar"
            >
              <v-img
                contain
                :src="require('@/assets/icons/campusDetail/places.svg')"
              />
            </v-list-item-avatar>
            <v-list-item-content style="text-align: start">
              <v-list-item-title
                class="campus-search-bar__results__item--title"
              >
                <span>
                  {{ place.city }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle
                class="campus-search-bar__results__item--subtitle"
              >
                <span v-t="place.upper_name" />
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="campus-search-bar__results__item--details"
              >
                <span v-html="place.label_subdivision" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="campus in suggestedCampuses"
            :key="campus.uuid"
            :title="`${campus.campus_short_name ?? campus.campus_name} ( ${campus.commune} )`"
            @click="selectCampus(campus)"
          >
            <v-list-item-avatar
              tile
              :color="getCampusThumbnail(campus) ? 'transparent' : 'grey lighten-2'"
              class="campus-search-bar__results__item--avatar"
              :class="{
                'campus-search-bar__results__item--avatar--transparent': getCampusThumbnail(campus),
              }"
            >
              <ImageAtom
                v-if="getCampusThumbnail(campus)"
                :src="getCampusThumbnail(campus)"
                is-responsive
              />
              <ImageAtom
                v-else
                class="campus-search-bar__results__item--avatar--default-icon"
                :src="require('@/assets/icons/campusDetail/infrastructure-icon.svg')"
              />
            </v-list-item-avatar>
            <v-list-item-content style="text-align: start">
              <v-list-item-title
                class="campus-search-bar__results__item--title"
              >
                <TitleVerifiedAccount
                  :title="getCampusName(campus)"
                  :prime="campus.campus_tether_pack"
                  :verified="campus.verified"
                />
              </v-list-item-title>
              <v-list-item-subtitle
                class="campus-search-bar__results__item--subtitle"
              >
                <span v-t="getInstitutionInfo(campus)" />
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="campus-search-bar__results__item--details"
              >
                <span v-html="campus.commune" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import CONFIG from '@/config';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import TitleVerifiedAccount from '@/components/molecules/verified_account/TitleVerifiedAccount.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'CampusSearchBar',
  components: {
    TitleVerifiedAccount,
    ImageAtom,
  },
  props: {
    inDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      selectedCampus: null,
      textSearchResults: [],
      textSearchPlaces: [],
      searchBarIsFocused: false,
      loading: false,
      checkSearchGrade: false,
    };
  },
  computed: {
    ...mapGetters({
      previouslySearchedCampuses: 'elasticSearch/previouslySearchedCampuses',
      previouslySearchedPlace: 'elasticSearch/previouslySearchedPlace',
      schools: 'institutions/schools',
      campusDetail: 'institutions/campusDetails',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    validSearchTerm() {
      return !!(this.searchTerm && this.searchTerm.length > 2);
    },
    suggestedCampuses() {
      if (this.validSearchTerm && !this.loading) {
        return this.textSearchResults;
      }
      if (this.loading) {
        return [];
      }
      return this.previouslySearchedCampuses;
    },
    suggestedPlaces() {
      if (this.validSearchTerm && !this.loading) {
        return this.textSearchPlaces;
      }
      if (this.loading) {
        return [];
      }
      return this.previouslySearchedPlace;
    },
    resultHeaderText() {
      if (this.validSearchTerm && this.suggestedCampuses.length > 0) {
        return this.$t('views.schools.results.exist', { searchTerm: this.searchTerm });
      }
      if (this.validSearchTerm && this.suggestedCampuses.length === 0) {
        return this.$t('views.schools.results.not-found', { searchTerm: this.searchTerm });
      }
      return this.$t('views.schools.results.previous-searches');
    },
  },
  watch: {
    searchTerm(newValue) {
      if (newValue && newValue.length > 2) {
        this.loading = true;
        this.retrieveElasticSearch({ searchTerm: newValue });
      }
    },
    // Schools are the markers in the map
    schools: {
      handler(campusMarkers) {
        if (this.checkSearchGrade) {
          // This handlers checks all schools in map, because they are there only if they match the grade
          // If the current school is not in the map, it means that the grade does not match
          // however, this seems to be inefficient, so we should find a better way to do this
          const getSchoolsPromise = new Promise((resolve) => {
            const schoolInMap = campusMarkers.find((schoolMarker) => schoolMarker.uuid === this.campusDetail.uuid);
            resolve(!schoolInMap);
          });
          getSchoolsPromise.then((schoolNotInMap) => {
            if (schoolNotInMap) {
              // this.warningSnackbar(this.$t('errors.guestGradeMatchError'));
            }
          });
        }
        this.checkSearchGrade = false;
      },
    },
    textSearchResults() {
      this.loading = false;
    },
  },
  methods: {
    ...mapActions({
      searchCampusesFromText: 'elasticSearch/searchCampusesFromText',
      addSearchedCampus: 'elasticSearch/addSearchedCampus',
      addSearchedPlace: 'elasticSearch/addSearchedPlace',
      selectSchoolFromSearchBar: 'institutions/selectSchoolFromSearchBar',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
      navigateTo: 'explorer/navigateTo',
      // warningSnackbar: 'utils/warn',
    }),
    getCampusName(campus) {
      const name = campus.short_name && campus.short_name !== '' ? campus.short_name : campus.campus_name;
      return name ?? '';
    },
    getInstitutionInfo(campus) {
      if (CONFIG.tenant === 'colombia') {
        return campus.campus_name;
      }
      if (CONFIG.tenant === 'chile') {
        return campus?.primary_national_code ? `RBD ${campus.primary_national_code}` : '';
      }
      if (
        CONFIG.tenant === 'newhaven'
        && campus.sector
      ) {
        if (this.$i18n.locale === 'en') {
          return `${campus.sector.sector_name_en}`;
        }
        return `${campus.sector.sector_name}`;
      }
      return campus.campus_name;
    },
    toggleSearchBar(boolean) {
      this.searchBarIsFocused = boolean;
    },
    selectCampus(campus) {
      this.selectedCampus = campus;
      this.addSearchedCampus({ campus });
      if (this.inDashboard) {
        this.setHeadMasterCurrentSchool(campus);
      } else {
        this.checkSearchGrade = true;
        this.changeSchool(campus);
      }
    },
    selectPlace(place) {
      this.addSearchedPlace({ place });
      const random = Math.random() / 100000;
      this.navigateTo({ lat: place.centroide_lat + random, lng: place.centroide_lng + random });
    },
    getCampusThumbnail(campus) {
      if (campus.image_thumb?.length > 0) {
        return campus.image_thumb[0].image_link;
      }
      return '';
    },
    cleanResults() {
      this.toggleSearchBar(false);
      this.searchTerm = null;
      this.textSearchResults = [];
      this.textSearchPlaces = [];
    },
    changeSchool(school) {
      if (school) {
        this.searching = true;
        this.selectSchoolFromSearchBar({ campusSearchData: school });
        this.schoolToSearch = null;
        this.cleanResults();
        this.$emit('clicked');
        trackMixPanel('open_school_profile3', {
          campus_code: school.campus_code,
          institution_code: school.institution_code,
          name: school.campus_name,
        });
      } else {
        // this.warningSnackbar(this.$t('errors.guestGradeMatchError'));
        this.schoolToSearch = null;
        this.cleanResults();
        this.$emit('clicked');
      }
    },
    setHeadMasterCurrentSchool(school) {
      this.searching = true;
      this.schoolToSearch = null;
      this.$emit('setLoading', false);
      this.cleanResults();
      this.retrieveCampusDetails({ searchCode: school.uuid }).finally(() => {
        this.$emit('setLoading', true);
      });
    },
    setTextFieldLabel() {
      if (this.loading) {
        return this.$t('views.schools.load-schools');
      }
      if (this.inDashboard && this.currentHeadMasterMainSchool) {
        return this.currentHeadMasterMainSchool.campus_name;
      }
      return this.$t('views.schools.search');
    },
    retrieveElasticSearch({ searchTerm }) {
      this.loading = true;
      this.searchCampusesFromText({ searchTerm })
        .then((response) => {
          if (response.status === 200) {
            const { campuses, places } = response;
            this.textSearchPlaces = [...places];
            this.textSearchResults = [...campuses];
          }
        })
        .catch(() => {
          this.textSearchResults = [];
          this.textSearchPlaces = [];
        });
    },
  },
};
</script>
